// Snowflakes.js
import React, { useEffect } from 'react';
import './Snowflakes.css'; // Импортируйте CSS для снежинок

const Snowflakes = () => {
    useEffect(() => {
        const createSnowflakes = () => {
            const snowflakeCount = 20; // Количество снежинок
            for (let i = 0; i < snowflakeCount; i++) {
                const snowflake = document.createElement('div');
                snowflake.className = 'snowflake';
                snowflake.textContent = '❄️'; // Символ снежинки
                snowflake.style.left = Math.random() * 100 + 'vw'; // Случайное положение по оси X
                snowflake.style.animationDuration = Math.random() * 3 + 3 + 's'; // Случайная скорость падения
                snowflake.style.fontSize = Math.random() * 1.5 + 0.5 + 'em'; // Случайный размер снежинки
                document.body.appendChild(snowflake);
            }
        };

        createSnowflakes();

        // Удаление снежинок после завершения анимации
        const removeSnowflakes = () => {
            const snowflakes = document.querySelectorAll('.snowflake');
            snowflakes.forEach(snowflake => {
                snowflake.remove();
            });
        };

        return () => {
            removeSnowflakes();
        };
    }, []);

    return null; // Этот компонент ничего не рендерит сам по себе
};

export default Snowflakes;
