import {
    AppBar,
    Avatar,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material"
import React from "react";
import { observer } from "mobx-react";
import { Logout, Settings } from "@mui/icons-material";
import { RouterLink, useRouterStore } from "mobx-state-router";
import { RoutesEnum } from "../../router";
import userStore from "../../store/UserStore";
import newYearImage from './ng.png';

export const Header = observer(() => {
    // иконка ведущая на домашнюю страницу, вход, выход
    const routerStore = useRouterStore();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogin = async () => {
        handleClose();
        await routerStore.goTo(RoutesEnum.LOGIN);
    }

    const handleLogout = async () => {
        handleClose();
        await userStore.logout();
        await routerStore.goTo(RoutesEnum.LOGIN);
    }

    return (
        <AppBar>
            <Toolbar>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        {userStore.isLoggedIn && <>
                            <Typography sx={{ minWidth: 100 }}>
                                <RouterLink routeName={RoutesEnum.HOME}>
                                    Заявки
                                </RouterLink>
                            </Typography>
                            <Typography sx={{ minWidth: 100 }}>
                                <RouterLink routeName={RoutesEnum.MY_REQUESTS}>
                                    В работе
                                </RouterLink>
                            </Typography>
                            <Typography sx={{ minWidth: 100 }}>
                                <RouterLink routeName={RoutesEnum.ARCHIVE_MY_REQUESTS}>
                                    Архив
                                </RouterLink>
                            </Typography>
                            <Typography sx={{ minWidth: 100 }}>
                                <RouterLink routeName={RoutesEnum.FAQ}>
                                    FAQ
                                </RouterLink>
                            </Typography>
                            <Box sx={{ minWidth: 200, display: 'flex', alignItems: 'center' }}>
            <img src={newYearImage} alt="С Новым Годом" style={{ right: '100px', width: '200px', height: '60px' }} />
    </Box>
                        </>}
                    </Box>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                            minWidth: 220,
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleClose}>
                        <Avatar /> {userStore?.me?.username || "Profile"}
                    </MenuItem>
                    <Divider />
                    {userStore.isLoggedIn ?
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Выйти
                        </MenuItem> :
                        <MenuItem onClick={handleLogin}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Войти
                        </MenuItem>
                    }
                </Menu>
            </Toolbar>
        </AppBar>
    );

    // const isAccount = true;
    //
    // const handleLogout = async () => {
    // }
    //
    // return (
    //     <AppBar position="static">
    //         <Toolbar>
    //             <IconButton edge="start" color="inherit" aria-label="menu">
    //             </IconButton>
    //             <Typography variant="h6" style={{flex: 1}}>
    //                 <RouterLink routeName={RoutesEnum.HOME}>
    //                     Заявки
    //                 </RouterLink>
    //             </Typography>
    //             <Button color="inherit">
    //                 <RouterLink routeName={RoutesEnum.LOGIN}>
    //                     Вход
    //                 </RouterLink>
    //             </Button>
    //             <Button color="inherit">
    //                 <RouterLink routeName={RoutesEnum.LOGIN}>
    //                     Выход
    //                 </RouterLink>
    //             </Button>
    //         </Toolbar>
    //     </AppBar>
    // )
})

/*

<IconButton edge="start" color="inherit" aria-label="menu">
    {<LocalShippingIcon/>}
</IconButton>
<Typography variant="h6" style={{flex: 1}}>
    <RouterLink routeName={RoutesEnum.HOME}>
        Доставка
    </RouterLink>
</Typography>
<Button color="inherit">
    <RouterLink routeName={RoutesEnum.LOGIN}>
        Вход
    </RouterLink>
</Button>
*/